<template>
  <div>
    <eden-page-stats
      class="mt-0"
      :title="'Overview'"
      :loading="loading"
      :stats="stats"
      :show-periods="false"
    >
      <template slot="actions">
        <eden-periods
          class="mr-10"
          :loading="loading"
          :default-period="'today'"
          :show-custom-period="true"
          :custom-title="'Orders for'"
          @set-period="getProvisionedOrders"
        />
        <eden-service-partners
          :loading="loading"
          :allow-all="true"
          :service-partner-name.sync="servicePartnerName"
          :service-partner-id.sync="servicePartnerId"
          :service="'laundry'"
        />
      </template>
    </eden-page-stats>
    <provisioned-orders-view
      :loading="loading"
      :service="'laundry'"
      :period="period"
      :orders="setProvisionedOrders"
      :orders-time="ordersTime"
      @confirmed="getProvisionedOrders({ period })"
    />
  </div>
</template>

<script>
import ProvisionedOrdersView from "@/components/ServiceOperations/ProvisionedOrders/ProvisionedOrdersView";

import { parseProvisionedOrders } from "../provisioned-orders.config";
import * as actions from "@/store/action-types";

export default {
  name: "ProvisionedOrdersLaundry",
  components: {
    ProvisionedOrdersView,
  },
  data() {
    return {
      loading: false,
      period: "today",
      periods: [
        "lastweek",
        "yesterday",
        "today",
        "tomorrow",
        "nexttwodays",
        "thisweek",
        "nextweek",
      ],
      custom: {
        from: null,
        to: null,
      },
      servicePartnerName: "all",
      servicePartnerId: "all",
      provisionedOrders: {
        all: {},
      },
      ordersTime: "",
    };
  },
  computed: {
    setProvisionedOrders() {
      return this.provisionedOrders[this.servicePartnerName].orders;
    },
    stats() {
      const { types, weight, customers, orders } =
        this.provisionedOrders[this.servicePartnerName];

        let stats = [
        {
          label: "Total customers",
          figure: (customers && customers.length) || "-",
        },
        {
          label: "Total orders",
          figure: (orders && orders.length) || "-",
        },
        {
          label: "Total weight",
          figure: orders && orders.length ? `${weight}kg` : "-",
        },
        {
          label: "Total items",
          figure: 0,
        },
      ];

      let laundryTypes = types && Object.keys(types);
      if (laundryTypes && laundryTypes.length) {
        laundryTypes.forEach((type) => {
          stats.splice(2, 0, {
            label: `${this.formatText(type.replaceAll("-", " "))} orders`,
            figure: types[type].total || 0,
          });
          stats.push({
            label: `Bags for ${type.replaceAll("-", " ")}`,
            figure: types[type].bags || 0,
          });
          stats.push({
            label: `Items for ${type.replaceAll("-", " ")}`,
            figure: types[type].items || 0,
          });
        });
      }

      return stats;
    },
  },
  created() {
    this.getProvisionedOrders({
      period: this.period,
      from: this.custom.from,
      to: this.custom.to,
    });
  },

  methods: {
    getProvisionedOrders({ period, from, to }) {
      this.loading = true;
      const { start_date, end_date } = this.formatStartAndEndDate({
        period: this.period,
        from,
        to,
      });
      this.$store
        .dispatch(actions.GET_PROVISIONED_ORDERS, {
          service: "laundry",
          start_date,
          end_date,
          from,
          to,
        })
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.period = period;
            this.provisionedOrders = parseProvisionedOrders(
              data,
              "laundry",
            );
            this.ordersTime = new Date();
            this.loading = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.loading = false;
        });
    },
  },
};
</script>
